const mobile = '@media screen and (max-width: 991px)';

export default (theme) => ({
  contentWrapper: {
    backgroundColor: theme.colors.flatBlue,
    position: 'relative',
    paddingTop: '6em',
    '&>main': {
      // marginTop: 129,
      // height: 600,
      // overflow: 'hidden',
      position: 'relative',
      width: '100%',

      // '&$fullPage': {
      //   height: 'auto',
      // },

      '&>section': {
        [theme.breakpoints.smDown]: {
          justifyContent: 'center',
        },
        // margin: '0 auto',
        // maxWidth: theme.breakpoints.values.xl,
        // paddingLeft: 32,
        // paddingRight: 32,
        width: '100%',
        minHeight: '70vh',
        [mobile]: {
          minHeight: '88vh',
        },
      },
    },
  },
  // fullPage: {},
  // headerSpacer: {
  //   backgroundColor: theme.colors.flatBlue,
  //   height: 170,
  // },
  root: {
    // backgroundColor: '#343C4D',
    height: '100%',
    minHeight: '100vh',
    overflowX: 'hidden',
  },
});
