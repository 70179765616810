import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { withStyles } from '@material-ui/core/styles';

import ReleaseNotesIcon from '../../../assets/svg/loginPageIcons/releaseNotesIcon.svg';
import TrainingMaterialsIcon from '../../../assets/svg/loginPageIcons/trainingMaterialIcon.svg';
import styles from './styles';

function Footer({ classes }) {
  return (
    <footer className={classes.root}>
      <div className={classes.linksWrapper}>
        <div className="leftItem">
          <Link href="/login">
            <ReleaseNotesIcon className={classes.icon} />
            <span className="caption">Release Notes</span>
          </Link>
        </div>

        <div className={classes.verticalLine} />
        <div className="center">
          <Link href="/login">
            <TrainingMaterialsIcon className={classes.icon} />
            <span className="caption">Training Materials</span>
          </Link>
        </div>
        <div className={classes.verticalLine} />
        <div className="rightItem">
          <Link href="/login" className={classes.longLink}>
            <img
              alt=""
              height="30"
              src="/static/images/login/Slack_RGB_White.png"
              width="35"
              className={classes.icon}
            />
            <span className="caption">
              For questions and suggestions, post a message in{' '}
              <strong>#WorkshopSupport</strong> channel on slack.
            </span>
          </Link>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const PureFooter = Footer;

export default withStyles(styles)(Footer);
