import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import WorkshopFeatures from '../../components/login/WorkshopFeatures';
import Footer from '../../components/login/Footer';
import Header from '../../components/login/Header';
import styles from './styles';

function LoginPageLayout({
  children,
  classes,
  fullPage,
  onRegisterConsumerClick,
  onRegisterProviderClick,
}) {
  return (
    <div className={classes.root}>
      <Header
        onRegisterConsumerClick={onRegisterConsumerClick}
        onRegisterProviderClick={onRegisterProviderClick}
      />
      <div className={classes.contentWrapper}>
        <main className={fullPage ? classes.fullPage : undefined}>
          {children}
        </main>
      </div>
      {/* Disable the footer section for external user testing */}
      {false && (
        <React.Fragment>
          <div>
            <WorkshopFeatures />
          </div>
          <div>
            <Footer />
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

LoginPageLayout.defaultProps = {
  children: undefined,
  fullPage: false,
  onRegisterConsumerClick: undefined,
  onRegisterProviderClick: undefined,
};

LoginPageLayout.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  fullPage: PropTypes.bool,
  onRegisterConsumerClick: PropTypes.func,
  onRegisterProviderClick: PropTypes.func,
};

export const PureLoginPageLayout = LoginPageLayout;

export default withStyles(styles)(LoginPageLayout);
