import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import clientConstants from '@app/config';
import LanguageDropdown from '../common/dropdowns/LanguageDropdown';
import { COLOR, SPACING, BREAKPOINT_VALUE } from '../homee-ui/theme';
import { Image } from '../homee-ui';

function Header() {
  const router = useRouter();

  return (
    <header>
      <div>
        <Link href="/login" className="logo-wrapper">
          <figure>
            <Image
              src={`${clientConstants.BRAND_PATH}/logo.svg`}
              alt={clientConstants.BRAND_NAME}
            />
          </figure>
        </Link>
      </div>
      <div className="language-wrapper">
        <LanguageDropdown
          id="language"
          language={router.locale as 'en' | 'fr'}
          onChange={(language) =>
            router.push(router.asPath, router.asPath, { locale: language })
          }
        />
      </div>
      <style jsx>
        {`
          header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: ${COLOR.neutral0};
            height: ${42 + SPACING.md + SPACING.md}px;
            position: fixed;
            right: 0;
            top: 0;
            width: 100%;
            z-index: 10;
            box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.18);
            font-family: 'Montserrat';
            padding: ${SPACING.md}px ${SPACING.lg}px;
          }

          .logo-wrapper {
            display: flex;
          }

          figure {
            margin: 0;
          }

          header a:hover {
            opacity: 0.8;
            text-decoration: none;
          }

          .language-wrapper {
            width: 150px;
          }

          @media (max-width: ${BREAKPOINT_VALUE.sm}px) {
            header {
              padding: ${SPACING.sm}px ${SPACING.md}px;
            }

            .logo-wrapper :global(img) {
              max-width: 160px;
            }
          }
        `}
      </style>
    </header>
  );
}

export default Header;
