import React from 'react';
import PropTypes from 'prop-types';
// import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Row, Col } from 'reactstrap';

// import Brandmark from '../../../assets/svg/brandmark.svg';
// import Button from '../../common/Button';
import WorkbenchIcon from '../../../assets/svg/loginPageIcons/workbenchIcon.svg';
import PeopleIcon from '../../../assets/svg/loginPageIcons/peopleIcon.svg';
import AnalyticsIcon from '../../../assets/svg/loginPageIcons/analyticsIcon.svg';
import PromosIcon from '../../../assets/svg/loginPageIcons/promosIcon.svg';
import styles from './styles';

function WorkshopFeatures({ classes }) {
  return (
    <section className={classes.root}>
      <div>
        <h1>Workshop Features:</h1>
      </div>
      <Row>
        <Col xs="6" sm="3" className={classes.features}>
          <div>
            <WorkbenchIcon /> <br />
            <span className="subText">workbench</span>
          </div>
          <div className="text">
            Manage active jobs &amp; <br /> Providers at a glance
          </div>
        </Col>
        <Col xs="6" sm="3" className={classes.features}>
          <div>
            <AnalyticsIcon /> <br />
            <span className="subText">Analytics</span>
          </div>
          <div className="text">
            Get real-time reporting <br /> on markets &amp; revenue
          </div>
        </Col>
        <Col xs="6" sm="3" className={classes.features}>
          <div>
            <PeopleIcon /> <br />
            <span className="subText">Profiles</span>
          </div>
          <div className="text">
            Upload resident and <br /> property details in bulk
          </div>
        </Col>
        <Col xs="6" sm="3" className={classes.features}>
          <div>
            <PromosIcon /> <br />
            <span className="subText">Promos</span>
          </div>
          <div className="text">
            Create, track &amp; manage <br /> promotions and referrals
          </div>
        </Col>
      </Row>
    </section>
  );
}

WorkshopFeatures.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const PureWorkshopFeatures = WorkshopFeatures;

export default withStyles(styles)(WorkshopFeatures);
