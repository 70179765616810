export default (theme) => ({
  root: {
    [theme.breakpoints.smDown]: {
      display: 'none',
    },
    marginTop: 32,
    fontFamily: 'Montserrat',
    // height: 96,
    // paddingLeft: 32,
    // paddingRight: 65,
    // position: 'relative',
    // transition: 'height 0.3s ease',
    // },

    '&>div h1': {
      fontFamily: 'Montserrat',
      fontWeight: 900,
      fontSize: 32,
      color: theme.colors.navyMediumDark,
      letterSpacing: '0.67px',
      textAlign: 'center',
      textTransform: 'capitalize',
    },
  },
  '& figure': {
    height: 0,
    margin: 0,
    padding: 0,
    position: 'relative',
  },
  features: {
    display: 'block',
    textAlign: 'center',
    justifyContent: 'center',
    marginTop: 30,
    marginBottom: 40,
    borderRight: `${theme.colors.navyLight30} 1px solid `,
    '&:last-child': {
      border: 'none !important',
      paddingRight: 42,
    },
    '&>div': {
      // display: 'block',
      '& svg': {
        marginBottom: 24,
      },
      '& span.subText': {
        fontSize: 12,
        color: theme.colors.red,
        fontWeight: theme.font.weights.bold,
        letterSpacing: '3px',
        textTransform: 'uppercase',
        // lineHeight: '32px',
        // fontFamily: 'Montserrat-Black',
      },
    },
    '&>div.text': {
      fontSize: 20,
      color: theme.colors.navyMediumDark,
      fontWeight: theme.font.weights.bold,
      letterSpacing: '0.33px',
      lineHeight: '24px',
      paddingBottom: 40,
    },
  },
  // },
});
