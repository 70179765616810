import Cookies from 'universal-cookie';
import React from 'react';
import { Dropdown } from '../../homee-ui';

const cookies = new Cookies();

type Language = 'en' | 'fr';

const options: { label: string; value: Language }[] = [
  { label: 'English', value: 'en' },
  { label: 'Français', value: 'fr' },
];

type Props = {
  id: string;
  language?: Language;
  onChange?: (language: Language) => void;
};

const LanguageDropdown: React.FC<Props> = ({
  id,
  language = cookies.get('NEXT_LOCALE') ?? 'en',
  onChange,
}) => (
  <Dropdown
    id={id}
    glyph="language"
    value={options.find((option) => option.value === language) ?? options[0]}
    onChange={(option) => {
      cookies.set('NEXT_LOCALE', option.value, { path: '/' });
      if (onChange) {
        onChange(option.value);
      }
    }}
    options={options}
  />
);

export default LanguageDropdown;
