export default (theme) => ({
  root: {
    color: theme.colors.white,
    backgroundColor: '#343c4d',
    display: 'flex',
    [theme.breakpoints.smDown]: {
      paddingBottom: 42,
      maxHeight: 100,
      paddingTop: 10,
    },
  },

  linksWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    margin: '0 auto',
    maxWidth: theme.breakpoints.values.xl,
    width: '100%',
    // paddingLeft: 32,
    // paddingRight: 32,
    paddingTop: 32,
    paddingBottom: 32,
    [theme.breakpoints.mdDown]: {
      padding: '15px 10px',
      justifyContent: 'space-between',
    },
    '&>div.leftItem': {
      flex: 1,
      '&>a': {
        display: 'flex',
        alignItems: 'center',
        color: 'currentColor',
        fontSize: 16,
        fontWeight: theme.font.weights.regular,
        letterSpacing: 0.22,
        textDecoration: 'none',
        marginLeft: 16,
        border: 'none',
        '& strong': {
          color: theme.colors.blue,
        },
        '&:hover': {
          opacity: 0.8,
          textDecoration: 'none',
          color: theme.colors.white,
        },
        [theme.breakpoints.mdDown]: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          color: theme.colors.white,
          '&>svg': {
            width: 10,
            height: 11,
            marginBottom: 10,
            marginRight: 10,
          },
          '&>span.caption': {
            [theme.breakpoints.smDown]: {
              borderRight: 'rgba(255,255,255,0.3) 1px solid',
              paddingRight: 15,
            },
            color: theme.colors.white,
            fontSize: 10,
            fontWeight: theme.font.weights.regular,
            lineHeight: '13px',
            letterSpacing: 0.14,
            textAlign: 'center',
          },
        },
      },
    },
    '&>div.center': {
      flex: 1,
      '&>a': {
        display: 'flex',
        alignItems: 'center',
        color: 'currentColor',
        fontSize: 16,
        fontWeight: theme.font.weights.regular,
        letterSpacing: 0.22,
        textDecoration: 'none',
        marginLeft: 16,
        border: 'none',
        '&:hover': {
          opacity: 0.8,
          textDecoration: 'none',
          color: theme.colors.white,
        },
        [theme.breakpoints.mdDown]: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          color: theme.colors.white,
          '&>svg': {
            width: 20,
            height: 19,
            marginBottom: 7,
            marginRight: 10,
          },
          '&>span.caption': {
            [theme.breakpoints.smDown]: {
              borderRight: 'rgba(255,255,255,0.3) 1px solid',
              paddingRight: 15,
            },
            color: theme.colors.white,
            fontSize: 10,
            fontWeight: theme.font.weights.regular,
            lineHeight: '13px',
            letterSpacing: 0.14,
            textAlign: 'center',
            //   borderRight: 'rgba(255,255,255,0.3) 1px solid',
            //   paddingRight: 15,
          },
        },
      },
    },
    '&>div.rightItem': {
      flex: 3,
      [theme.breakpoints.smDown]: {
        height: 50,
      },
      '&>a': {
        display: 'flex',
        alignItems: 'center',
        color: 'currentColor',
        fontSize: 16,
        fontWeight: theme.font.weights.regular,
        letterSpacing: 0.22,
        textDecoration: 'none',
        marginLeft: 16,
        '& strong': {
          color: theme.colors.blue,
        },
        '&:hover': {
          opacity: 0.8,
          textDecoration: 'none',
          color: theme.colors.white,
        },
        [theme.breakpoints.mdDown]: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          color: theme.colors.white,
          '&>img': {
            // paddingTop: 10,
            width: 12,
            height: 14,
            marginBottom: 9,
          },
          '&>span.caption': {
            color: theme.colors.white,
            fontSize: 10,
            fontWeight: theme.font.weights.regular,
            lineHeight: '13px',
            letterSpacing: 0.14,
            textAlign: 'center',

            '& strong': {
              color: theme.colors.blue,
            },
          },
        },
      },
    },
  },

  link: {
    [theme.breakpoints.mdDown]: {
      display: 'block',
      flex: 1,
      margin: 0,
      padding: 0,
      textAlign: 'center',
      fontSize: 10,
      fontWeight: theme.font.weights.regular,
      letterSpacing: 0.14,
    },
    display: 'flex',
    alignItems: 'center',
    color: 'currentColor',
    fontSize: 16,
    fontWeight: theme.font.weights.regular,
    letterSpacing: 0.22,
    whiteSpace: 'nowrap',
    textDecoration: 'none',
    marginLeft: 16,
    '& strong': {
      color: theme.colors.blue,
    },
    '&:hover': {
      opacity: 0.8,
      textDecoration: 'none',
      color: theme.colors.white,
    },
  },
  longLink: {
    whiteSpace: 'normal',
  },
  icon: {
    [theme.breakpoints.mdDown]: {
      marginRight: 0,
      marginBottom: 10,
    },
    marginRight: 16,
  },

  verticalLine: {
    height: 50,
    marginLeft: 30,
    marginRight: 10,
    borderLeft: 'rgba(255,255,255,0.3) 1px solid',
    [theme.breakpoints.smDown]: {
      display: 'none',
      // height: 34,
      // margin: '30px 10px 16px 10px',
    },
  },
});
